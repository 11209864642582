<template>
  <top-agent-self-transfer v-if="this.$store.getters.userRole === 'top_agent'" />
  <agency-self-transfer v-else-if="this.$store.getters.userRole === 'agency'" />
  <merchant-self-transfer v-else-if="this.$store.getters.userRole === 'merchant'" />
</template>

<script>
import TopAgentSelfTransfer from '@/views/transfers/top_agent_self/index'
import AgencySelfTransfer from '@/views/transfers/agency_self/index'
import MerchantSelfTransfer from '@/views/transfers/merchant_self/index'

export default {
  name: 'SideMenu',
  components: {
    TopAgentSelfTransfer,
    AgencySelfTransfer,
    MerchantSelfTransfer
  }
}
</script>
