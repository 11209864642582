import request from '@/utils/request'

// 查询客户收付款记录
export function findAgentTransfers(params) {
  return request({
    url: `/agent_transfers`,
    method: 'get',
    params: params
  })
}

// 支付方式选项数据
export function findTransferPayWayOptions() {
  return request({
    url: `/transfers/pay_way/options`,
    method: 'get'
  })
}

// 收付款类型选项数据
export function findTransferTypeOptions() {
  return [
    { label: '线上充值', value: 'online_deposit' },
    { label: '线下充值', value: 'offline_deposit' }
  ]
}

// 记录企业用户收付款
export function createAgencyTransfer(data) {
  return request({
    url: `/agent_transfers`,
    method: 'post',
    data
  })
}

// 导出收付款记录
export function exportAgentTransfers(data) {
  return request({
    url: `/agent_transfers/export`,
    method: 'post',
    data
  })
}
